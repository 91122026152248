
<template>
    <div>
        <b-alert v-model="uploadSuccess" dismissible variant="success">
            Thanks, we got the photo. Feel free to upload as many as you like.
        </b-alert>
        <div v-if="uploadFailed" class="text-center p-2">
            <div class="alert alert-danger">The photo failed to upload</div>

            <button
                class="btn btn-primary btn-lg btn-block mt-2"
                @click="reset"
            >
                Try again
            </button>
        </div>
        <div v-else>
            <uploadcare
                :publicKey="uploadcareKey"
                @success="onSuccess"
                @error="onError"
                :previewStep="false"
                :multiple="true"
                :imagesOnly="false"
                tabs="file camera url gdrive gphotos facebook instagram dropbox onedrive"
            >
                <button class="btn btn-primary btn-lg btn-block">
                    <i class="fas fa-file-upload"></i>
                    Upload Photo
                </button>
            </uploadcare>
        </div>
    </div>
</template>


<script>
import Uploadcare from "uploadcare-vue";

export default {
    data() {
        return {
            uploadcareKey: process.env.VUE_APP_UPLOADCARE_PUBLIC_KEY,
            uploadSuccess: false,
            uploadFailed: false,
            uploading: false,
            files: [],
        };
    },
    components: {
        uploadcare: Uploadcare,
    },
    methods: {
        onSuccess(uploaded) {
            this.$http
                .post(
                    this.route("cashforcars.flow.api.offer.uploadUrl", {
                        type: this.type,
                        url: uploaded.cdnUrl,
                    })
                )
                .then(() => {
                    this.$emit("uploaded");
                    this.uploadSuccess = true;
                })
                .catch(() => {
                    this.uploadFailed = true;
                })
                .then(() => {});
        },
        onError() {
            this.uploadFailed = true;
        },
        reset() {
            this.uploading = false;
            this.uploadSuccess = false;
            this.uploadFailed = false;
        },
    },
    props: ["type"],
};
</script>