
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-header">
                <h1 class="">Mileage</h1>
                <div class="lead text-secondary mb-3">
                    How many miles are on the vehicle?
                </div>
            </div>
            <div class="step-body">
                <b-form @submit.stop.prevent="confirmMileage">
                    <b-form-group>
                        <b-form-input
                            autocomplete="none"
                            size="lg"
                            v-model="mileage"
                            inputmode="numeric"
                            type="text"
                            placeholder="Enter mileage..."
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                        <b-button
                            :disabled="!mileage"
                            type="submit"
                            block
                            size="lg"
                            variant="primary"
                        >
                            <b-spinner small v-if="saving"></b-spinner>
                            <span v-else>Continue</span>
                        </b-button>

                        <div class="mt-3" v-if="!mileage">
                            <b-button
                                @click="unsure"
                                block
                                size="sm"
                                class="text-secondary"
                                variant="link"
                            >
                                <b-spinner small v-if="saving"></b-spinner>
                                <span v-else>I'm not sure</span>
                            </b-button>
                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </div>

        <b-modal hide-footer v-model="modal">
            <template #modal-title> Please confirm mileage </template>
            <b-form-group class="text-center">
                <b-alert variant="warning" show>
                    Please confirm your vehicle's mileage
                </b-alert>
                <h1 class="text-secondary my-4 display-4">
                    {{ formattedMileage }}
                </h1>
            </b-form-group>
            <b-row no-gutters>
                <b-col>
                    <b-btn
                        @click="modal = false"
                        variant="link"
                        block
                        size="lg"
                    >
                        Make Changes
                    </b-btn>
                </b-col>
                <b-col>
                    <b-btn @click="save" variant="primary" block size="lg">
                        Looks Good
                    </b-btn>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>


<style scoped>
</style>

<script>
export default {
    data() {
        return {
            saving: false,
            mileage: "",
            modal: false,
        };
    },
    computed: {
        formattedMileage() {
            return parseInt(this.mileageNumeric).toLocaleString();
        },
        mileageNumeric() {
            return this.mileage.replace(/\D/g, "");
        },
    },
    created() {},
    methods: {
        confirmMileage() {
            if (
                parseInt(this.mileageNumeric) < 10000 ||
                parseInt(this.mileageNumeric) > 200000
            ) {
                this.modal = true;
            } else {
                this.save();
            }
        },
        unsure() {
            this.$root.$emit("next");
        },
        save() {
            this.saving = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.mileage"), {
                    mileage: `${this.mileage}`,
                })
                .then(() => {
                    this.track({
                        name: "completedMileage",
                        category: "seller",
                        value: 5,
                    });

                    this.$root.$emit("next");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {},
    beforeDestroy: function () {},
};
</script>