<template>
    <div>
        <div class="hero splash mb-5 pb-5">
            <div class="hero-body p-4 mt-md-5">
                <h1 class="mb-0">We Buy Junk Cars</h1>
                <div class="my-3 lead text-muted">
                    We'll tow it away and pay you cash. Free towing included,
                    same-day in select locations.
                </div>

                <div class="my-3 d-none d-md-block">
                    <b-btn
                        class="my-2"
                        @click="next"
                        size="lg"
                        variant="primary"
                        block
                    >
                        <b-spinner small v-if="saving"></b-spinner>
                        <span v-else>Get Instant Cash Offer</span>
                    </b-btn>
                    <b-btn
                        v-if="callingEnabled"
                        class="my-2"
                        @click="$root.$emit('call')"
                        size="lg"
                        variant="link"
                        block
                    >
                        Give Us a Call
                    </b-btn>
                </div>

                <div class="mobile-hero-footer d-block d-md-none">
                    <b-btn @click="next" variant="primary" size="lg" block>
                        <b-spinner small v-if="saving"></b-spinner>
                        <span v-else>
                            Get Instant Cash Offer
                            <small class="d-block">
                                Takes less than 30 seconds
                            </small>
                        </span>
                    </b-btn>
                </div>
            </div>
        </div>
        <div class="below-fold container">
            <div class="mt-5">
                <h3>Hassle-Free Quote</h3>
                <div>
                    Our free and instant online quote will accurately determine
                    the value of your vehicle based on a quick few questions.
                    You can be sure the price you see is what you'll get paid in
                    cash today.
                </div>
            </div>
            <div class="mt-5">
                <h3>Yes, We Pay Cash</h3>
                <div>
                    We can pick up your vehicle and give you cash in hand today.
                    It only takes a few clicks to get the value of your car with
                    our free and easy instant quote system. Just enter your
                    vehicle and a few questions and we'll give you the real
                    price that we can offer.
                </div>
            </div>
            <div class="mt-5 mb-5">
                <h3>Same Day Pick-up</h3>
                <div class="text-muted small">* in select locations</div>
                <div>
                    Give us a call today, we'd be happy to give you a no
                    obligation quote for the price of your vehicle. We
                    understand sometimes you just want to get rid of it and tow
                    it away.
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-hero-footer small {
    margin-top: 4px;
    font-size: 12px;
    color: #ccc;
}
@media (min-width: 1200px) {
    .hero {
        background-image: url(/img/caddy.jpg);
        background-size: 1102px 560px;
        background-position: right -400px top 50%;
        background-repeat: no-repeat;
        justify-content: unset !important;
        margin-left: 10vw;
    }
}
.hero {
    height: calc(100vh - var(--navbar-header-height));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}
.hero .hero-body {
    max-width: 500px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            saving: false,
            phone: this.$root.phone.formatted,
        };
    },
    computed: mapState(["callingEnabled"]),
    methods: {
        next() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.$root.$emit("next");
        },
    },
    props: ["keyword", "region"],
};
</script>
