
<template>
    <div class="step">
        <div class="step-content">
            <h1>Get the best price</h1>
            <div class="lead text-secondary">
                Providing your VIN will increase your offer up to 80%. Without
                it we'll only be able to provide a scrap price, which is
                significantly lower on most models.
            </div>
            <b-form @submit.stop.prevent="save" class="mt-3">
                <b-alert show v-if="error" variant="danger">
                    {{ error }}
                </b-alert>
                <b-form-group>
                    <b-form-input
                        size="lg"
                        autocomplete="none"
                        pattern="[a-zA-Z0-9\s]+"
                        v-model="vin"
                        placeholder="Vehicle Identification Number (VIN)"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-button type="submit" block size="lg" variant="primary">
                        <b-spinner small v-if="saving"></b-spinner>
                        <span v-else>Continue</span>
                    </b-button>
                </b-form-group>
                <b-form-group>
                    <b-button
                        @click="skip"
                        block
                        size="sm"
                        class="text-secondary"
                        variant="link"
                    >
                        Skip for now
                    </b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            error: null,
            saving: false,
            vin: null,
        };
    },

    created() {},
    methods: {
        skip() {
            this.$root.$emit("next");
        },
        save() {
            this.error = null;
            this.saving = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.setVin"), {
                    vin: this.vin,
                })
                .then(() => {
                    this.$root.$emit("next");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {},
};
</script>