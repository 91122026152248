
<template>
    <div class="step step-fullscreen">
        <div class="step-content">
            <div class="step-body">
                <div>
                    <div
                        class=""
                        v-bind:class="{ 'd-none': vehicleStep !== 'year' }"
                    >
                        <h1>Year</h1>
                        <div class="vehicle-step-title">
                            What year was your vehicle made?
                        </div>
                        <div
                            class="btn btn-light mr-3 mb-3 btn-sm"
                            v-for="year in vehicleYears"
                            v-bind:key="year.vehicle_year_id"
                            v-on:click="selectVehicleYear(year.vehicle_year_id)"
                        >
                            {{ year.year }}
                        </div>
                    </div>
                    <div
                        class=""
                        v-bind:class="{ 'd-none': vehicleStep !== 'make' }"
                    >
                        <h1>Make</h1>
                        <div class="vehicle-step-title">
                            Who makes the vehicle?
                        </div>
                        <div class="scroll-wrapper">
                            <div
                                class="btn btn-light btn-sm mr-3 mb-3"
                                v-for="make in vehicleMakes"
                                v-bind:key="make.vehicle_make_id"
                                v-on:click="
                                    selectVehicleMake(make.vehicle_make_id)
                                "
                            >
                                {{ make.name }}
                            </div>
                        </div>
                    </div>
                    <div
                        class=""
                        v-bind:class="{ 'd-none': vehicleStep !== 'model' }"
                    >
                        <h1>Model</h1>
                        <div class="vehicle-step-title">What model is it?</div>
                        <div
                            class="btn btn-light mr-3 mb-3"
                            v-for="model in vehicleModels"
                            v-bind:key="model.vehicle_model_id"
                            v-on:click="
                                selectVehicleModel(model.vehicle_model_id)
                            "
                        >
                            {{ model.name }}
                        </div>
                    </div>
                    <div
                        class=""
                        v-bind:class="{ 'd-none': vehicleStep !== 'trim' }"
                    >
                        <h1>Trim</h1>
                        <div class="vehicle-step-title">What is the trim?</div>
                        <div
                            class="btn btn-light mr-3 mb-3"
                            v-for="trim in vehicleTrims"
                            v-bind:key="trim.vehicle_trim_id"
                            v-on:click="selectVehicleTrim(trim.vehicle_trim_id)"
                        >
                            {{ trim.name }}
                        </div>
                        <div class="mt-1 small">
                            <a @click="unknownTrim" href="javascript://;"
                                >I'm not sure</a
                            >
                        </div>
                    </div>

                    <div
                        class="
                            w-full
                            d-flex
                            align-items-center
                            justify-content-around
                            py-5
                            my-5
                        "
                        v-if="showVehicleSpinner"
                    >
                        <b-spinner />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="canResetVehicle" class="step-footer custom">
            <div class="ml-1">
                <a
                    style="font-size: 19px"
                    href="javascript://;"
                    class="text-danger small cursor-pointer mr-3"
                    @click="resetVehicle"
                >
                    <i class="fal fa-times"></i>
                </a>
            </div>
            <div class="selected-item lead">{{ vehicleCompleteName }}</div>
        </div>
    </div>
</template>


<style scoped>
.step-footer.custom .selected-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.step-footer.custom {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    padding: 10px;
}

.vehicle-step-title {
    font-size: 21px;
    color: #666;
    margin-bottom: 15px;
}
.scroll-wrapperx {
    height: 100vh;
}
</style>

<script>
export default {
    data() {
        return {
            fullscreen: false,
            errorMessage: "",
            vehicle_trim_id: null,

            showVehicleSpinner: false,
            vehicleStep: "year",
            selectedYear: null,
            vehicleYears: [],
            vehicleMakes: [],
            vehicleModels: [],
            vehicleTrims: [],
            vehicleNameParts: [],
            vehicleState: {},
        };
    },

    created() {
        this.loadVehicleData();
    },
    computed: {
        canResetVehicle: function () {
            if (this.vehicleStep === "done" || this.vehicleStep === "year") {
                return false;
            }
            return true;
        },
        vehicleCompleteName: function () {
            return this.vehicleNameParts.join(" ");
        },
    },
    methods: {
        resetVehicle() {
            this.vehicle_trim_id = null;
            this.vehicleNameParts = [];
            this.vehicleStep = "year";
            this.vehicleState = {};
            this.fullscreen = true;
        },
        async selectVehicleYear(vehicle_year_id) {
            this.showVehicleSpinner = true;
            this.vehicleStep = "make";
            this.fullscreen = true;

            this.vehicleState = {
                vehicle_year_id,
            };

            this.selectedYear = this.vehicleYears.find(
                (year) => year.vehicle_year_id == vehicle_year_id
            ).year;

            this.vehicleNameParts.push(this.selectedYear);

            this.$http
                .get(this.route("api.vehicle.make"), {
                    params: this.vehicleState,
                })
                .then((response) => {
                    this.vehicleMakes = response.data.makes;
                    this.showVehicleSpinner = false;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        async selectVehicleMake(vehicle_make_id) {
            this.showVehicleSpinner = true;
            this.vehicleStep = "model";
            this.vehicleState = {
                ...this.vehicleState,
                vehicle_make_id,
            };
            this.vehicleNameParts.push(
                this.vehicleMakes.find(
                    (make) => make.vehicle_make_id == vehicle_make_id
                ).name
            );
            this.fullscreen = true;

            this.$http
                .get(this.route("api.vehicle.model"), {
                    params: this.vehicleState,
                })
                .then((response) => {
                    this.showVehicleSpinner = false;
                    this.vehicleModels = response.data.models;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        async selectVehicleModel(vehicle_model_id) {
            this.showVehicleSpinner = true;
            this.vehicleStep = "trim";
            this.vehicleState = {
                ...this.vehicleState,
                vehicle_model_id,
            };
            this.vehicleNameParts.push(
                this.vehicleModels.find(
                    (make) => make.vehicle_model_id == vehicle_model_id
                ).name
            );

            this.$http
                .get(this.route("api.vehicle.trim"), {
                    params: this.vehicleState,
                })
                .then((response) => {
                    this.showVehicleSpinner = false;
                    this.vehicleTrims = response.data.trims;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        async selectVehicleTrim(vehicle_trim_id) {
            this.vehicleStep = "done";
            this.vehicleState = {
                ...this.vehicleState,
                vehicle_trim_id,
            };
            this.fullscreen = false;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.trim"), {
                    vehicle_trim_id: this.vehicleState.vehicle_trim_id,
                })
                .then(() => {
                    this.completedVehicle();
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        completedVehicle() {
            this.track({
                name: "completedVehicle",
                category: "seller",
                value: 2,
            });

            if (this.$root?.flowType === "auction") {
                const yearsDiff =
                    new Date().getFullYear() - parseInt(this.selectedYear);

                if (yearsDiff <= 12) {
                    this.track({
                        name: "lateModelAuctionVehicle",
                        category: "seller",
                        value: 1,
                    });
                }
            }

            this.$emit("next");
        },
        async unknownTrim() {
            this.$http
                .post(
                    this.route("cashforcars.flow.api.offer.unknownTrim"),
                    this.vehicleState
                )
                .then(() => {
                    this.completedVehicle();
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        async loadVehicleData() {
            this.$http
                .get(this.route("api.vehicle.year"))
                .then((response) => {
                    this.vehicleYears = response.data.years;
                    this.showVehicleSpinner = false;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        error(message) {
            this.errorMessage = message;
        },
    },
    beforeDestroy: function () {},
};
</script>