
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-header">
                <div class="d-flex align-items-center">
                    <h1>Zip code</h1>
                </div>
            </div>
            <div class="step-body">
                <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div class="lead mb-3">
                    What zip code is the vehicle located at?
                </div>

                <b-form @submit.stop.prevent="save">
                    <b-form-group>
                        <b-form-input
                            autocomplete="postal-code"
                            size="lg"
                            v-model="zip"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            type="text"
                            placeholder="Enter zip code..."
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" block size="lg" variant="primary">
                        <b-spinner small v-if="saving"></b-spinner>
                        <span v-else>Continue</span>
                    </b-button>
                </b-form>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
export default {
    data() {
        return {
            saving: false,
            errorMessage: "",
            zip: "",
        };
    },

    created() {},
    methods: {
        error(message) {
            this.errorMessage = message;
        },
        save() {
            this.errorMessage = null;
            this.saving = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.zip"), {
                    zip: this.zip,
                })
                .then(() => {
                    this.track({
                        name: "completedZip",
                        category: "seller",
                        value: 1,
                    });

                    this.$emit("next");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {},
    beforeDestroy: function () {},
};
</script>