
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-body">
                <div v-if="conditions.length">
                    <transition name="fade" mode="out-in">
                        <div :key="current.id">
                            <h3>{{ current.question }}</h3>
                            <div v-if="current.explainer" class="text-muted">
                                {{ current.explainer }}
                            </div>

                            <div v-if="saving" class="my-4">
                                <b-spinner small></b-spinner>
                            </div>
                            <div v-else class="mt-4">
                                <div v-if="current.type === 'select'">
                                    <b-btn
                                        :key="option.id"
                                        v-for="option in current.options"
                                        @click="select(current, option)"
                                        block
                                        variant="light"
                                        size="lg"
                                        class="my-3"
                                    >
                                        {{ option.name }}
                                    </b-btn>
                                </div>
                                <b-row v-if="current.type === 'binary'">
                                    <b-col
                                        :key="option.id"
                                        v-for="option in current.options"
                                    >
                                        <b-btn
                                            @click="select(current, option)"
                                            variant="light"
                                            size="lg"
                                            block
                                        >
                                            {{ option.name }}
                                        </b-btn>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </transition>
                </div>
                <div v-else class="text-center p-3">
                    <b-spinner></b-spinner>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
export default {
    data() {
        return {
            saving: false,
            triggeredNext: false,
            pending: false,
            current: {},
            conditions: [],
        };
    },
    created() {
        this.conditions = this.$root.conditions;
        this.offer = this.$root.offer;

        this.conditions = this.conditions.filter((condition) =>
            this.required ? condition.required : !condition.required
        );

        this.next();
    },
    methods: {
        next() {
            this.current = this.conditions[0];
        },
        select(condition, option) {
            this.saving = true;

            this.conditions = this.conditions.filter(
                (searchCondition) => searchCondition.id !== condition.id
            );

            if (this.conditions.length) {
                this.next();
            }

            this.saving = false;

            this.$http
                .post(
                    this.route("cashforcars.flow.api.offer.condition", {
                        condition: condition.id,
                        option: option.id,
                    })
                )
                .then(() => {
                    if (!this.triggeredNext && !this.conditions.length) {
                        this.track({
                            name: this.required
                                ? "completedRequiredConditions"
                                : "completedOptionalConditions",
                            category: "seller",
                            value: 10,
                        });

                        this.$root.$emit("next");
                        this.triggeredNext = true;
                    }
                })
                .catch(() => {});
        },
    },
    props: ["required"],
};
</script>