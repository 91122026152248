
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-body">
                <div>
                    <div
                        v-if="offer && offer.quote && !rejected && !hasQuestion"
                    >
                        <div class="text-center">
                            <div v-if="hasUpdatedQuote">
                                <h1>Your updated offer</h1>
                            </div>
                            <div v-else>
                                <h1>Your offer</h1>
                            </div>
                            <h1 class="display-3 text-success">
                                {{ offer.quote.amountFormatted }}
                            </h1>
                        </div>
                        <div class="lead mb-3">
                            Congratulations! We can offer you
                            <strong>
                                {{ offer.quote.amountFormatted }}
                            </strong>
                            for your vehicle.
                        </div>
                        <button
                            @click="accept"
                            class="btn btn-primary btn-block btn-lg p-3"
                        >
                            Accept Offer
                        </button>
                        <div>
                            <button
                                @click="showOptions = true"
                                class="btn btn-link btn-block text-muted mt-3"
                            >
                                More options
                            </button>
                        </div>
                        <div v-if="showOptions">
                            <button
                                v-if="!hasUpdatedQuote"
                                @click="reject"
                                class="btn btn-light btn-block text-muted mt-3"
                            >
                                No thanks, I don't accept
                            </button>
                            <button
                                @click="hasQuestion = true"
                                class="btn btn-light btn-block text-muted mt-3"
                            >
                                I have a question about my offer
                            </button>
                        </div>
                    </div>
                    <div v-if="!offer || !offer.quote" class="text-center">
                        <h1>One sec</h1>
                        <div class="lead">We're crunching the numbers...</div>
                        <div class="mt-3">
                            <div class="text-center mx-auto">
                                <b-spinner
                                    style="width: 3rem; height: 3rem"
                                    variant="primary"
                                ></b-spinner>
                            </div>
                        </div>
                        <div
                            class="
                                mt-3
                                text-left
                                mx-auto
                                small
                                text-muted
                                d-inline-block
                            "
                        >
                            <div class="my-1">
                                <i class="fas fa-check text-success"></i>
                                Guaranteed Price
                            </div>
                            <div class="my-1">
                                <i class="fas fa-check text-success"></i>
                                Cancel Anytime
                            </div>
                            <div class="my-1">
                                <i class="fas fa-check text-success"></i>
                                4.8 Star Rating on TrustPilot
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="rejected">
                    <button
                        @click="rejected = false"
                        class="small text-primary btn btn-link px-0"
                    >
                        <i class="fas fa-arrow-left"></i>
                        Back to my offer
                    </button>
                    <h1>Get a better price</h1>
                    <div v-if="!uploadTitle">
                        <div class="lead">
                            82% of our offers with VIN get a higher price.
                        </div>
                        <div class="mt-3">
                            We may be able to increase your offer
                            <strong>significantly</strong> with more
                            information.
                        </div>
                    </div>
                    <div v-if="showActions">
                        <button
                            @click="
                                showActions = false;
                                uploadTitle = true;
                            "
                            class="btn btn-primary btn-block btn-lg mt-3"
                        >
                            Upload a photo of my title
                            <div class="small text-light">(recommended)</div>
                        </button>
                        <button
                            @click="
                                showActions = false;
                                enterVin = true;
                            "
                            class="btn btn-light btn-block text-muted mt-3"
                        >
                            Enter VIN Manually
                        </button>
                    </div>
                    <div class="mt-3" v-if="uploadTitle">
                        <div class="clearfix mt-3">
                            <div class="float-right">
                                <img
                                    class="ml-2 pt-2"
                                    style="width: 100px"
                                    src="https://scrapcars-assets.s3.amazonaws.com/graphic/example-title-pa.png"
                                />
                            </div>
                            <div class="lead">
                                Uploading your title is the easiest way to get
                                your VIN into our system. We will automatically
                                detect the VIN using Ai, and use that
                                information to get you the best possible quote.
                            </div>
                            <div class="text-success small mt-3">
                                <i class="fas fa-lock mr-1"></i>
                                Secure Encrypted Upload
                            </div>
                        </div>

                        <uploader type="title" />
                    </div>
                    <div class="mt-3" v-if="enterVin">
                        <div v-if="errorMessage" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group">
                            <label>
                                VIN (Vehicle Identification Number)
                                <span
                                    @click="
                                        enterVin = false;
                                        showActions = true;
                                    "
                                    class="text-danger small ml-2"
                                >
                                    Cancel
                                </span>
                            </label>
                            <input
                                autocomplete="none"
                                pattern="[a-zA-Z0-9\s]+"
                                type="text"
                                class="form-control form-control-lg"
                                v-model="vin"
                                placeholder="Vehicle Identification Number"
                            />
                        </div>
                        <div class="form-group">
                            <button
                                @click="setVin"
                                type="submit"
                                class="btn btn-primary btn-lg btn-block"
                            >
                                Get Updated Offer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="hasQuestion">
                    <div v-if="askedQuestion">
                        <button
                            @click="
                                hasQuestion = false;
                                askedQuestion = false;
                            "
                            class="small text-primary btn btn-link px-0"
                        >
                            <i class="fas fa-arrow-left"></i>
                            Back to my offer
                        </button>
                        <h1>Question Submitted</h1>
                        <div class="lead">
                            Thanks, we'll follow up with a text message shortly.
                        </div>
                        <div>
                            <a
                                class="btn btn-lg btn-light mt-3"
                                @click="$root.$emit('call')"
                                href="#"
                            >
                                <div>Call us instead</div>
                            </a>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="errorMessage" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group">
                            <button
                                @click="hasQuestion = false"
                                class="small text-primary btn btn-link px-0"
                            >
                                <i class="fas fa-arrow-left"></i>
                                Back to my offer
                            </button>
                            <h1>We're here to help</h1>
                            <label>
                                <div class="lead">
                                    Ask a question, and we'll text you back
                                    shortly with answers to any questions.
                                </div>
                            </label>

                            <textarea
                                v-model="question"
                                class="form-control"
                                rows="4"
                                placeholder="Ask a question about your offer"
                            ></textarea>
                        </div>
                        <div class="form-group mb-0">
                            <button
                                @click="submitQuestion"
                                type="submit"
                                class="btn btn-primary btn-lg btn-block"
                            >
                                Submit Question
                            </button>
                        </div>
                        <div v-if="callingEnabled" class="form-group mt-2">
                            <a
                                class="btn btn-link btn-block"
                                @click="$root.$emit('call')"
                                href="#"
                            >
                                <div>Or Call Now</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-footer"></div>
    </div>
</template>


<style scoped>
</style>

<script>
import Uploader from "./../Feature/Uploader";
import { mapState } from "vuex";
import * as mutations from "@/store/mutations";

export default {
    data() {
        return {
            showActions: true,
            askedQuestion: false,
            question: "",
            hasQuestion: false,
            hasUpdatedQuote: false,
            vin: "",
            uploadTitle: false,
            enterVin: false,
            rejected: false,
            showOptions: false,
            offer: null,
            errorMessage: "",
            attempt: 0,
        };
    },
    computed: mapState(["callingEnabled"]),
    created() {
        this.getQuote();
    },
    methods: {
        submitQuestion() {
            this.askedQuestion = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.question"), {
                    question: this.question,
                })
                .then(() => {});
        },
        upload() {
            this.$emit("next");
        },
        reject() {
            this.rejected = true;

            this.track({
                name: "quoteRejected",
                category: "seller",
                label: "Rejected Quote",
                value: 0,
            });

            this.$http
                .post(
                    this.route(
                        "cashforcars.flow.api.quote.reject",
                        this.offer.quote.id
                    )
                )
                .then(() => {});
        },
        accept() {
            this.$store.commit(mutations.ENABLE_CALLING);

            this.track({
                name: "quoteAccepted",
                category: "seller",
                label: "Accepted Quote",
                value: 20,
            });

            this.$http
                .post(
                    this.route(
                        "cashforcars.flow.api.quote.accept",
                        this.offer.quote.id
                    )
                )
                .then(() => {
                    this.$emit("next");
                });
        },
        async getQuote() {
            const tryToGetQuote = () => {
                this.attempt += 1;

                return new Promise((resolve) => {
                    if (this.offer?.quote) {
                        return resolve();
                    }

                    setTimeout(() => {
                        this.$http
                            .get(this.route("cashforcars.flow.api.offer.get"))
                            .then((response) => {
                                if (!response?.data?.offer?.quote) {
                                    return;
                                }

                                this.offer = response.data?.offer;
                            })
                            .catch(() => {})
                            .then(() => {
                                return resolve();
                            });
                    }, this.attempt * 1000);
                });
            };

            // quote may be still rendering, may take several seconds to finish the process
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();
            await tryToGetQuote();

            if (!this.offer?.quote) {
                alert(
                    "Sorry, we are having trouble loading your quote, please try and refresh the page to load your quote."
                );

                this.track({
                    name: "failedToLoadQuote",
                    category: "seller-error",
                    value: 0,
                });
            }
        },
        reset() {
            this.rejected = null;
            this.offer = null;
            this.showOptions = null;
        },
        setVin() {
            this.errorMessage = null;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.setVin"), {
                    vin: this.vin,
                })
                .then(() => {
                    this.hasUpdatedQuote = true;
                    this.reset();
                    this.getQuote();
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                });
        },
        error(message) {
            this.errorMessage = message;
        },
    },
    components: {
        Uploader,
    },
    beforeDestroy: function () {},
};
</script>