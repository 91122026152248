
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-header">
                <h1>Let's get started</h1>
                <div class="lead text-secondary">
                    We'll text you a copy of your offer so you can move forward
                    at your convenience.
                </div>
            </div>
            <div class="step-body mt-3">
                <b-form @submit.stop.prevent="save" class="mt-3">
                    <b-alert show v-if="error" variant="danger">
                        {{ error }}
                    </b-alert>
                    <b-form-group class="mb-0">
                        <b-form-input
                            size="lg"
                            autocomplete="tel"
                            type="tel"
                            v-model="phone"
                            placeholder="Mobile Phone Number"
                        ></b-form-input>
                    </b-form-group>
                    <div class="small text-muted my-3">
                        By pressing "Continue", you hereby agree to our
                        <a
                            class="text-muted"
                            target="_blank"
                            :href="route('content.terms')"
                        >
                            Terms and Conditions
                        </a>
                        and
                        <a
                            class="text-muted"
                            target="_blank"
                            :href="route('content.privacy')"
                        >
                            Privacy Policy
                        </a>
                    </div>
                    <b-form-group>
                        <b-button
                            type="submit"
                            block
                            size="lg"
                            variant="primary"
                        >
                            <b-spinner small v-if="saving"></b-spinner>
                            <span v-else>Continue</span>
                        </b-button>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
export default {
    data() {
        return {
            saving: false,
            error: null,
            phone: null,
        };
    },
    created() {},
    methods: {
        save() {
            this.error = null;
            this.saving = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.phone"), {
                    phone: this.phone,
                })
                .then(() => {
                    this.track({
                        name: "phoneNumberCollected",
                        category: "seller",
                        label: "Collected Phone Number",
                        value: 10,
                    });

                    this.$emit("next");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>