<template>
    <div>
        <div class="header shadow-sm">
            <div class="h-100">
                <img
                    class="brand-logo"
                    height="100"
                    width="100"
                    src="https://scrapcars-frontend-assets.s3.amazonaws.com/brand/cashforcars/white-background-borderless-scalable.svg"
                    alt="CashForCars"
                />
            </div>
            <div class="pr-3">
                <a
                    v-if="!currentStep"
                    class="btn btn-link mr-3 d-none d-md-inline-block"
                    href="/flow/default?start=1"
                >
                    Get Instant Cash Offer
                </a>
                <a
                    v-if="callingEnabled"
                    class="btn btn-outline-primary d-none d-sm-inline-block"
                    :href="`tel:${$root.phone.formatted}`"
                >
                    {{ $root.phone.formatted }}
                </a>
                <a
                    v-if="callingEnabled"
                    ref="callButton"
                    class="btn btn-outline-primary d-inline-block d-sm-none"
                    :href="`tel:${$root.phone.formatted}`"
                >
                    <div>Call Now</div>
                    <div class="text-muted small">
                        {{ $root.phone.formatted }}
                    </div>
                </a>
            </div>
        </div>
        <div class="progress-wrapper position-fixed w-100" style="top: 100px">
            <div class="progress" style="height: 1px">
                <div
                    v-bind:class="{ 'bg-success': complete }"
                    class="progress-bar"
                    role="progressbar"
                    :style="`width: ${percentageComplete}%`"
                ></div>
            </div>
        </div>
        <div
            v-if="!loaded"
            style="margin-top: -100px"
            class="d-flex align-items-center justify-content-around vh-100"
        >
            <div class="text-center">
                <b-spinner large variant="secondary"></b-spinner>
                <div class="text-secondary my-2">Loading...</div>
            </div>
        </div>
        <div v-else>
            <transition name="fade" mode="out-in">
                <name v-if="currentStep === 'name'" />
                <vin v-if="currentStep === 'vin'" />

                <condition
                    key="requiredConditions"
                    :required="true"
                    v-if="currentStep === 'requiredConditions'"
                />
                <condition
                    key="optionalConditions"
                    :required="false"
                    v-if="currentStep === 'optionalConditions'"
                />

                <splash
                    :keyword="keyword"
                    :region="region"
                    v-if="splashScreen"
                />

                <resume
                    v-if="hasExistingOffer"
                    @resumeFlow="resumeFlow"
                    @reset="reset"
                />
                <phone
                    type="default"
                    v-if="currentStep === 'phone'"
                    @next="next"
                />
                <vehicle v-if="currentStep === 'vehicle'" @next="next" />
                <mileage v-if="currentStep === 'mileage'" />
                <quote v-if="currentStep === 'quote'" @next="next" />
                <documents v-if="currentStep === 'documents'" @next="next" />
                <zip v-if="currentStep === 'zip'" @next="next" />
                <fulfillment
                    v-if="currentStep === 'fulfillment'"
                    @next="next"
                />

                <confirmation
                    v-if="currentStep === 'confirmation'"
                    @next="next"
                />
            </transition>
        </div>
    </div>
</template>

<style>
@import "./css/app.css";
</style>

<script>
import Echo from "laravel-echo";

import Splash from "@/components/Flow/Splash";
import Resume from "@/components/Flow/Resume";
import Phone from "@/components/Flow/Step/Phone";
import Name from "@/components/Flow/Step/Name";
import Vehicle from "@/components/Flow/Step/Vehicle";
import Mileage from "@/components/Flow/Step/Mileage";
import Quote from "@/components/Flow/Step/Quote";
import Zip from "@/components/Flow/Step/Zip";
import Vin from "@/components/Flow/Step/Vin";
import Fulfillment from "@/components/Flow/Step/Fulfillment";
import Confirmation from "@/components/Flow/Step/Confirmation";
import Documents from "@/components/Flow/Step/Documents";
import Condition from "@/components/Flow/Step/Condition";

import { mapState } from "vuex";
import * as mutations from "@/store/mutations";

export default {
    metaInfo() {
        return {
            title: "CashforCars.io",
        };
    },
    data() {
        return {
            steps: [
                "phone",
                "zip",
                "vehicle",
                "documents",
                "mileage",
                "requiredConditions",
                "vin",
                "quote",
                "name",
                "fulfillment",
                "optionalConditions",
                "confirmation",
            ],
            baseUrl: "/flow/default",
            splashScreen: true,
            hasExistingOffer: false,
            currentStep: null,
            percentageComplete: 0,
            complete: false,
            loaded: false,
            initializedPresenceChannel: false,
        };
    },
    computed: {
        ...mapState(["callingEnabled"]),
        enableCalling() {
            if (this.$route?.query?.enableCalling) {
                return true;
            }

            return false;
        },
    },
    watch: {
        callingEnabled(value) {
            if (value) {
                this.$nextTick(() => {
                    window.CallTrk.swap();
                });
            }
        },
    },
    async created() {
        window.requestIdleCallback(() => {
            this.$loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`
            );
        });

        window.Pusher = require("pusher-js");

        // window.Pusher.logToConsole = true

        window.Echo = new Echo({
            broadcaster: "pusher",
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            encrypted: true,
            authorizer: (channel) => {
                return {
                    authorize: (socketId, callback) => {
                        this.$http
                            .post(
                                `${process.env.VUE_APP_BASE_ENDPOINT}/api/broadcasting/auth`,
                                {
                                    socket_id: socketId,
                                    channel_name: channel.name,
                                }
                            )
                            .then((response) => {
                                callback(false, response.data);
                            })
                            .catch((error) => {
                                callback(true, error);
                            });
                    },
                };
            },
        });

        this.$root.$on("next", async () => {
            this.next();
        });

        await this.startup();
        await this.refresh();

        if (
            this.enableCalling ||
            this.offer?.seller?.calling_enabled ||
            this.offer?.qualified
        ) {
            this.$store.commit(mutations.ENABLE_CALLING);
        }

        this.loaded = true;

        if (
            this.offer &&
            this.offer.current_step &&
            this.steps.includes(this.offer.current_step)
        ) {
            this.splashScreen = false;
            this.hasExistingOffer = true;

            if (this.autoResume) {
                this.resumeFlow();
            }

            return;
        }

        // splash screen bypass
        // from seo entry point
        if (this.start) {
            this.splashScreen = false;
            this.next();
        }
    },
    mounted() {
        this.$root.$on("call", () => {
            // in case they trigger a call event and the button is not yet enabled
            // go ahead and enable it
            if (!this.callingEnabled) {
                this.$store.commit(mutations.ENABLE_CALLING);
            }

            this.$nextTick(() => {
                this.$refs.callButton.click();
            });
        });
    },
    methods: {
        joinPresenceChannel() {
            if (this.initializedPresenceChannel) {
                return;
            }

            this.initializedPresenceChannel = true;

            window.Echo.join(`chat.${this.offer.seller_id}`);
            window.Echo.join(`seller-online`);
        },
        async startup() {
            // Make first two requests
            const [, configResponse] = await Promise.all([
                this.$http.get(
                    `${process.env.VUE_APP_BASE_ENDPOINT}/sanctum/csrf-cookie`
                ),
                this.$http.get(
                    `${process.env.VUE_APP_BASE_ENDPOINT}/api/flow/config`
                ),
            ]);

            const token = this.$route.query?.public_key
                ? await this.exchangePublicKeyForToken()
                : await this.getToken();

            await new Promise((resolve) => {
                this.$http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${token}`;

                this.$root.phone = configResponse.data.phone;
                this.$root.conditions = configResponse.data.conditions;

                return resolve();
            });
        },
        refresh() {
            return new Promise((resolve) => {
                this.$http
                    .get(this.route("cashforcars.flow.api.offer.get"), {
                        params: {
                            enterprise_buyer_id: process.env.VUE_APP_BUYER_ID,
                        },
                    })
                    .then((response) => {
                        this.offer = response?.data?.offer;
                        this.$root.offer = this.offer;
                        return resolve();
                    });
            });
        },
        generate() {
            return new Promise((resolve) => {
                this.$http
                    .get(this.route("cashforcars.flow.api.offer.new"), {
                        params: {
                            enterprise_buyer_id: process.env.VUE_APP_BUYER_ID,
                        },
                    })
                    .then((response) => {
                        this.offer = response?.data?.offer;
                        this.$root.offer = this.offer;
                        return resolve();
                    });
            });
        },
        async exchangePublicKeyForToken() {
            return new Promise((resolve, reject) => {
                if (localStorage.getItem("sellerToken")) {
                    return resolve(localStorage.getItem("sellerToken"));
                }

                this.$http
                    .post(this.route("cashforcars.flow.api.seller.exchange"), {
                        public_key: this.$route.query.public_key,
                    })
                    .then((response) => {
                        if (!response?.data?.token) {
                            return reject();
                        }

                        const { token } = response.data;

                        localStorage.setItem("sellerToken", token);

                        return resolve(response.data.token);
                    });
            });
        },
        async getToken() {
            return new Promise((resolve, reject) => {
                if (localStorage.getItem("sellerToken")) {
                    return resolve(localStorage.getItem("sellerToken"));
                }

                this.$http
                    .post(this.route("cashforcars.flow.api.seller.generate"), {
                        params: location.search,
                        referrer: document.referrer,
                        userAgent: navigator.userAgent,
                    })
                    .then((response) => {
                        if (!response?.data?.token) {
                            return reject();
                        }

                        const { token } = response.data;

                        localStorage.setItem("sellerToken", token);

                        return resolve(response.data.token);
                    });
            });
        },
        resumeFlow() {
            this.hasExistingOffer = false;
            this.currentStep = this.offer.current_step;

            this.joinPresenceChannel();
        },
        reset() {
            this.$http
                .get(this.route("cashforcars.flow.api.offer.reset"))
                .then(async () => {
                    await this.refresh();

                    this.hasExistingOffer = false;
                    this.next();
                });
        },
        async next() {
            if (!this.offer) {
                await this.generate();
                await this.refresh();
            }

            this.joinPresenceChannel();

            let nextIndex;

            if (this.currentStep) {
                nextIndex =
                    this.steps.findIndex((step) => step === this.currentStep) +
                    1;
            } else {
                nextIndex = 0;
                this.splashScreen = false;
            }

            this.currentStep = this.steps[nextIndex];

            window.gtag("config", "UA-27956843-2", {
                page_path: `${this.baseUrl}/${this.currentStep}`,
            });

            this.$http.post(
                this.route("cashforcars.flow.api.offer.saveCurrentStep"),
                {
                    step: this.currentStep,
                }
            );

            this.percentageComplete = Math.round(
                ((nextIndex + 1) / this.steps.length) * 100
            );

            if (this.percentageComplete === 100) {
                this.complete = true;
            }
        },
    },
    components: {
        Splash,
        Resume,
        Phone,
        Name,
        Vehicle,
        Mileage,
        Quote,
        Confirmation,
        Fulfillment,
        Zip,
        Documents,
        Condition,
        Vin,
    },
    props: [
        "brand",
        "start",
        "keyword",
        "region",
        "phone",
        "token",
        "autoResume",
    ],
};
</script>
