
<template>
    <div class="step step-fullscreen">
        <div class="step-content">
            <div class="step-body">
                <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div v-if="fulfillmentStep === 'type'" class="mb-4">
                    <h1>Free tow</h1>
                    <div class="lead">
                        Did you want a free tow? We'll come to you and tow your
                        vehicle away.
                    </div>

                    <div class="mt-3">
                        <div class="">
                            <button
                                @click="setMethod('pickup')"
                                class="
                                    btn btn-light btn-block btn-lg
                                    rounded-lg
                                "
                            >
                                Yes
                            </button>
                        </div>
                        <div class="mt-3">
                            <button
                                @click="setMethod('dropoff')"
                                class="btn btn-block btn-link"
                            >
                                No, I'll drop off my vehicle
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="fulfillmentStep === 'time'">
                    <h1>
                        Schedule
                        <span class="text-capitalize">
                            {{ fulfillment_method }}
                        </span>
                    </h1>
                    <div class="lead">
                        Request a time slot that works best for you.
                    </div>
                    <div class="row mt-3">
                        <div
                            class="col-12 col-md-6 mb-3"
                            :key="timeslot.dayOfWeekIso"
                            v-for="timeslot in timeslots"
                        >
                            <div class="text-center">
                                <h6 class="mb-0">
                                    {{ timeslot.label }}
                                </h6>
                                <div class="text-muted small mb-1">
                                    {{ timeslot.formattedDateString }}
                                </div>
                            </div>

                            <div
                                class="mb-2"
                                :key="index"
                                v-for="(slot, index) in timeslot.slots"
                            >
                                <div
                                    v-if="slot.available"
                                    class="btn btn-light btn-block"
                                    @click="selectTimeslot(timeslot.date, slot)"
                                >
                                    <span v-if="slot.selected">
                                        <i
                                            class="fal fa-check text-primary"
                                        ></i>
                                    </span>
                                    {{ slot.start.label }}
                                    -
                                    {{ slot.finish.label }}
                                </div>
                                <div
                                    v-else
                                    disabled
                                    class="btn btn-light btn-block disabled"
                                >
                                    {{ slot.start.label }}
                                    -
                                    {{ slot.finish.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="fulfillmentStep === 'address'">
                    <h1>
                        <span class="text-capitalize">
                            {{ fulfillment_method }}
                        </span>
                        location
                    </h1>
                    <div class="lead">Where are we picking up the vehicle?</div>
                    <div class="mt-3">
                        <div v-if="offer.fulfillment_address">
                            <a
                                @click="resetAddressInput"
                                href="javascript://;"
                                class="text-danger small"
                            >
                                Reset Address
                            </a>
                        </div>

                        <div class="form-group">
                            <div class="input-group mb-3 input-group-lg">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fab fa-google"></i>
                                    </span>
                                </div>
                                <vue-google-autocomplete
                                    :country="['us']"
                                    id="map"
                                    classname="form-control form-control-lg"
                                    placeholder="Pickup Location"
                                    autocomplete="false"
                                    v-on:placechanged="selectAddress"
                                >
                                </vue-google-autocomplete>
                            </div>
                        </div>

                        <div class="form-group">
                            <textarea
                                rows="2"
                                v-model="fulfillment_notes"
                                class="form-control form-control-lg"
                                placeholder="Any other pickup instructions (optional)"
                                maxlength="252"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div v-if="showContinue" class="form-group">
                    <b-form-group>
                        <b-button
                            @click="save"
                            type="submit"
                            block
                            size="lg"
                            variant="primary"
                        >
                            <b-spinner small v-if="saving"></b-spinner>
                            <span v-else>Continue</span>
                        </b-button>
                    </b-form-group>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>


<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    data() {
        return {
            saving: null,
            errorMessage: "",
            showContinue: false,
            fulfillmentStep: "type",
            offer: {},
            timeslots: null,
            fulfillment_notes: null,
            fulfillment_address: null,
            fulfillment_address_meta: null,
            fulfillmentComplete: false,
            meta: {},
            errors: [],
            selectedSchedule: false,
            props: {},
        };
    },
    components: {
        VueGoogleAutocomplete,
    },
    created() {
        this.$http
            .get(this.route("cashforcars.flow.api.offer.timeslots"))
            .then((response) => {
                this.timeslots = response.data.timeslots;
            })
            .catch(() => {});
    },
    computed: {
        showAddressInput: function () {
            if (this.offer.fulfillment_method === "dropoff") {
                return false;
            }

            return true;
        },
    },
    methods: {
        error(message) {
            this.errorMessage = message;
        },
        save() {
            this.saving = true;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.fulfillment"), {
                    fulfillment_method: this.fulfillment_method,
                    fulfillment_address: this.fulfillment_address,
                    fulfillment_notes: this.fulfillment_notes,
                    time_slot_date: this.time_slot_date,
                    time_slot_start: this.time_slot_start,
                    time_slot_finish: this.time_slot_finish,
                })
                .then(() => {
                    this.track({
                        name: "completedFulfillment",
                        category: "seller",
                        value: 5,
                    });

                    this.$emit("next");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error(error.response.data.message);
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        setMethod(fulfillment_method) {
            this.fulfillment_method = fulfillment_method;
            this.fulfillmentStep = "time";
        },
        selectTimeslot(date, slot) {
            this.time_slot_date = date;
            this.time_slot_start = slot.start.value;
            this.time_slot_finish = slot.finish.value;

            if (this.fulfillment_method === "pickup") {
                this.fulfillmentStep = "address";
                this.showContinue = true;
            } else {
                this.save();
            }
        },

        genericErrorHandler(response) {
            response.json().then((json) => {
                const { errors } = json;
                for (const error in errors) {
                    this.errors.push({
                        field: error,
                        message: errors[error].join("<hr />"),
                    });
                }
            });
        },
        resetAddressInput() {
            this.offer.fulfillment_address = null;
        },
        selectAddress(...googleResult) {
            const [, placeData] = googleResult;

            this.hasSelectedAddress = true;
            this.fulfillment_address = placeData.formatted_address;
            this.fulfillment_address_meta = googleResult;
        },
        resetFulfillment() {
            this.offer.fulfillment_method = "pickup";
        },
    },
};
</script>
