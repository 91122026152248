import Vue from 'vue'
import VueLuxon from "vue-luxon";
import * as Sentry from "@sentry/vue";
import { BootstrapVue } from 'bootstrap-vue'
import store from '@/store/index'
import Axios from 'axios'
import route from 'ziggy-js';
import LoadScript from "vue-plugin-load-script";
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import App from '@/App.vue'

// prod
// import { Ziggy } from './ziggy';

// dev
let Ziggy;

(async () => {
    const response = await Axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/api/flow/config`);
    const { ziggy } = response.data;
    Ziggy = ziggy;
})();

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.prototype.$http = Axios;

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    tracingOptions: {
        trackComponents: true,
    },
    environment: process.env.MIX_APP_ENV,
    logErrors: true,
});

Vue.mixin({
    methods: {
        route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
        track(event = {}) {
            window.gtag('event', event.name, {
                'event_category': event.category,
                'event_label': event.label,
                'value': 0
            });
        }
    },
});

Vue.use(BootstrapVue)
Vue.use(VueLuxon);
Vue.use(LoadScript);
Vue.use(VueRouter)
Vue.use(VueMeta)

new Vue({
    router: new VueRouter({ mode: 'history' }),
    store,
    render: h => h(App),
}).$mount('#app')
