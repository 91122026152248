<template>
    <div>
        <div class="hero splash mb-5 pb-5">
            <div class="hero-body p-4 mt-md-5">
                <h1 class="">You're almost there</h1>
                <div class="my-2 text-success">
                    <i class="fas fa-check-circle text-success"></i>
                    We still have the information you entered.
                </div>
                <div class="mb-4">
                    Do you want to keep going and pick up where you left off, or
                    start over with a new offer?
                </div>

                <div class="mt-3">
                    <div v-if="loading">
                        <b-spinner />
                    </div>
                    <div v-else>
                        <a
                            @click="resume"
                            href="javascript://;"
                            class="btn btn-primary btn-lg btn-block"
                        >
                            <div>Keep Going</div>
                        </a>
                        <a
                            @click="reset"
                            href="javascript://;"
                            class="btn btn-light btn-lg btn-block"
                        >
                            Start Over
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-hero-footer small {
    margin-top: 4px;
    font-size: 12px;
    color: #ccc;
}
@media (min-width: 1200px) {
    .hero {
        background-image: url(/img/caddy.jpg);
        background-size: 1102px 560px;
        background-position: right -400px top 50%;
        background-repeat: no-repeat;
        justify-content: unset !important;
        margin-left: 10vw;
    }
}
.hero {
    height: calc(100vh - var(--navbar-header-height));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}
.hero .hero-body {
    max-width: 500px;
}
</style>

<script>
export default {
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        resume() {
            this.loading = true;
            this.$emit("resumeFlow");
        },
        reset() {
            this.loading = true;
            this.$emit("reset");
        },
    },
};
</script>