import Vue from 'vue'
import Vuex from 'vuex'
import * as mutations from './mutations'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        callingEnabled: false,
    },
    mutations: {
        [mutations.ENABLE_CALLING](state) {
            state.callingEnabled = true
        },
    },
    strict: debug,
})