
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-body">
                <div>
                    <div v-if="!offer">
                        <div class="d-flex justify-content-around py-5">
                            <b-spinner />
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="offer.documentation === 'none'">
                            <div class="text-center">
                                <div class="py-1">
                                    <i
                                        class="
                                            fal
                                            fa-check-circle
                                            text-success
                                            fa-5x
                                        "
                                    ></i>
                                </div>
                                <h1>You're all set</h1>
                            </div>
                            <div class="mt-3">
                                <div class="lead text-secondary">
                                    Thanks, we got all of the information
                                    required, we'll be reaching out to you
                                    shortly. You can expect a text or call from
                                    one of our agents from the number below.
                                </div>
                            </div>
                            <b-card class="text-center mt-3" no-body>
                                <b-list-group flush>
                                    <b-list-group-item class="text-muted">
                                        We'll contact you from this number
                                    </b-list-group-item>
                                    <b-list-group-item variant="success">
                                        <h4 class="mb-0">
                                            {{ $root.phone.formatted }}
                                        </h4>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </div>
                        <div v-else>
                            <h1>Reserve your slot</h1>
                            <div v-if="offer.documentation === 'title'">
                                <div class="clearfix mt-3">
                                    <div class="float-right">
                                        <img
                                            class="ml-2 pt-2"
                                            style="width: 100px"
                                            src="https://scrapcars-assets.s3.amazonaws.com/graphic/example-title-pa.png"
                                        />
                                    </div>
                                    <div class="lead">
                                        We're ready to purchase your vehicle.
                                        The last step is to upload a photo of
                                        your title.
                                    </div>
                                    <div class="text-success small mt-3">
                                        <i class="fas fa-lock mr-1"></i>
                                        Secure Encrypted Upload
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div>
                                        <uploader type="title" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="offer.documentation === 'registration'">
                                <div class="clearfix mt-3">
                                    <div class="float-right">
                                        <img
                                            class="ml-2 pt-2"
                                            style="width: 100px"
                                            src="https://scrapcars-frontend-assets.s3.amazonaws.com/flow/example-registration-pa.png"
                                        />
                                    </div>
                                    <div class="lead">
                                        We're ready to purchase your vehicle.
                                        The last step is to upload a photo of
                                        your registration and photo ID.
                                    </div>
                                    <div class="text-success small mt-3">
                                        <i class="fas fa-lock mr-1"></i>
                                        Secure Encrypted Upload
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div>
                                        <uploader type="registration" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
import Uploader from "./../Feature/Uploader";

export default {
    data() {
        return {
            offer: null,
            errorMessage: "",
        };
    },
    created() {
        this.$http
            .get(this.route("cashforcars.flow.api.offer.get"))
            .then((response) => {
                this.offer = response.data?.offer;
            });

        this.track({
            name: "viewedConfirmation",
            category: "seller",
            value: 1,
        });

        this.$root.$on("done-uploading", () => {
            // fake it
            // should probably re-do this entire step as its a clusterf***
            this.offer.documentation = "none";
        });
    },
    methods: {
        error(message) {
            this.errorMessage = message;
        },
    },

    components: {
        Uploader,
    },
};
</script>
