
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-body">
                <div v-if="!offer" class="text-center py-5">
                    <b-spinner variant="secondary" />
                </div>
                <div v-else>
                    <div key="unsupported" v-if="mode === 'unsupported'">
                        <div class="form-group">
                            <h5 class="mb-0">
                                Unfortunately, we cannot purchase your vehicle
                                without the legally required documents
                            </h5>
                        </div>
                        <div class="form-group">
                            <button
                                class="btn btn-light btn-block"
                                @click="resolve(resolutions.looking)"
                            >
                                {{ resolutions.looking }}
                            </button>
                        </div>
                        <div class="form-group">
                            <button
                                class="btn btn-light btn-block"
                                @click="resolve(resolutions.renewing)"
                            >
                                {{ resolutions.renewing }}
                            </button>
                        </div>
                        <div class="form-group">
                            <button
                                class="btn btn-light btn-block"
                                @click="reset"
                            >
                                {{ resolutions.reset }}
                            </button>
                        </div>
                    </div>
                    <div key="questions" v-if="mode === 'questions'">
                        <transition name="fade" mode="out-in">
                            <div key="hasTitle" v-if="hasTitle === null">
                                <h3>{{ titleQuestion }}</h3>
                                <div class="small text-muted mt-2">
                                    It's ok if you don't, we can still work with
                                    other forms of documentation
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <button
                                            @click="answerTitleQuestion(true)"
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            Yes
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            @click="answerTitleQuestion(false)"
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                key="hasRegistration"
                                v-else-if="hasRegistration === null"
                            >
                                <h3>{{ registrationQuestion }}</h3>
                                <div class="small text-muted mt-2">
                                    We can only accept registration if it has
                                    not expired
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <button
                                            @click="
                                                answerRegistrationQuestion(true)
                                            "
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            Yes
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            @click="
                                                answerRegistrationQuestion(
                                                    false
                                                )
                                            "
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                key="registrationInName"
                                v-else-if="registrationInName === null"
                            >
                                <h3>{{ registrationInNameQuestion }}</h3>
                                <div class="small text-muted mt-2">
                                    We can only accept registration under your
                                    name
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <button
                                            @click="
                                                answerRegistrationInNameQuestion(
                                                    true
                                                )
                                            "
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            Yes
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            @click="
                                                answerRegistrationInNameQuestion(
                                                    false
                                                )
                                            "
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                key="havePhotoId"
                                v-else-if="havePhotoId === null"
                            >
                                <h3>{{ havePhotoIdQuestion }}</h3>
                                <div class="small text-muted mt-2">
                                    We can only accept registration if you have
                                    photo ID that matches
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <button
                                            @click="answerPhotoIdQuestion(true)"
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            Yes
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            @click="
                                                answerPhotoIdQuestion(false)
                                            "
                                            class="
                                                btn btn-light btn-block btn-lg
                                                rounded-lg
                                            "
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
const defaultDataState = {
    mode: "questions",
    havePhotoId: null,
    registrationInName: null,
    hasTitle: null,
    hasRegistration: null,
    offer: null,
};

export default {
    data() {
        return {
            ...defaultDataState,
            titleQuestion: "Do you have the title for the vehicle?",
            registrationQuestion:
                "Do you have current registration for the vehicle?",
            registrationInNameQuestion: "Is the registration in your name?",
            havePhotoIdQuestion: "Do you have photo ID?",
            resolutions: {
                looking: "I have the documents, I just need to find them",
                renewing:
                    "I will renew my documents so you can purchase my vehicle",
                reset: "I made a mistake, I need to start over",
            },
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.$http
                .get(this.route("cashforcars.flow.api.offer.get"))
                .then((response) => {
                    this.offer = response.data?.offer;
                });
        },
        completedDocuments() {
            this.track({
                name: "completedDocuments",
                category: "seller",
                value: 3,
            });

            this.$emit("next");
        },
        resolve() {
            this.$http
                .post(this.route("cashforcars.flow.api.offer.documentation"), {
                    documentation: "none",
                })
                .then(() => {
                    this.completedDocuments();
                })
                .catch(() => {});
        },
        answerTitleQuestion(trueOrFalse) {
            // const payload = {
            //     question: this.titleQuestion,
            //     trueOrFalse,
            // };

            // if yes, we're done with this step, save and move on
            if (trueOrFalse === true) {
                return this.$http
                    .post(
                        this.route("cashforcars.flow.api.offer.documentation"),
                        {
                            documentation: "title",
                        }
                    )
                    .then(() => {
                        this.completedDocuments();
                    })
                    .catch(() => {});
            }

            // if no, and no docs required, we're also done
            if (this.offer.documentation_required === "none") {
                return this.$http
                    .post(
                        this.route("cashforcars.flow.api.offer.documentation"),
                        {
                            documentation: "none",
                        }
                    )
                    .then(() => {
                        this.completedDocuments();
                    })
                    .catch(() => {});
            }

            // if no but reg is required...
            if (this.offer.documentation_required === "registration") {
                this.hasTitle = false;
            } else {
                this.mode = "unsupported";
            }
        },
        answerRegistrationQuestion(trueOrFalse) {
            // const payload = {
            //     question: this.registrationQuestion,
            //     trueOrFalse,
            // };

            if (trueOrFalse === true) {
                this.hasRegistration = true;
            } else {
                this.mode = "unsupported";
            }
        },
        answerRegistrationInNameQuestion(trueOrFalse) {
            // const payload = {
            //     question: this.registrationInNameQuestion,
            //     trueOrFalse,
            // };

            if (trueOrFalse === true) {
                this.registrationInName = true;
            } else {
                this.mode = "unsupported";
            }
        },
        answerPhotoIdQuestion(trueOrFalse) {
            // const payload = {
            //     question: this.havePhotoIdQuestion,
            //     trueOrFalse,
            // };

            if (trueOrFalse === true) {
                this.$http
                    .post(
                        this.route("cashforcars.flow.api.offer.documentation"),
                        {
                            documentation: "registration",
                        }
                    )
                    .then(() => {
                        this.completedDocuments();
                    })
                    .catch(() => {});
            } else {
                this.mode = "unsupported";
            }
        },
        reset() {
            Object.assign(this.$data, defaultDataState);
            this.refresh();
        },
    },
};
</script>