
<template>
    <div class="step">
        <div class="step-content">
            <div class="step-header">
                <h1>Name</h1>
                <div class="lead text-secondary">
                    Please provide your name as it is shown on your
                    documentation/ID.
                </div>
            </div>
            <div class="step-body">
                <b-form @submit.stop.prevent="save" class="mt-3">
                    <b-alert show v-if="error" variant="danger">
                        {{ error }}
                    </b-alert>
                    <b-form-group class="mb-2">
                        <b-form-input
                            autocomplete="given-name"
                            size="lg"
                            v-model="first_name"
                            placeholder="First Name"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            autocomplete="family-name"
                            size="lg"
                            v-model="last_name"
                            placeholder="Last Name"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-button
                            type="submit"
                            block
                            size="lg"
                            variant="primary"
                        >
                            <b-spinner small v-if="saving"></b-spinner>
                            <span v-else>Continue</span>
                        </b-button>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
export default {
    data() {
        return {
            saving: false,
            error: null,
            first_name: null,
            last_name: null,
        };
    },
    created() {},
    methods: {
        save() {
            this.saving = true;
            this.error = null;

            this.$http
                .post(this.route("cashforcars.flow.api.offer.name"), {
                    phone: this.phone,
                    first_name: this.first_name,
                    last_name: this.last_name,
                })
                .then(() => {
                    this.saving = true;
                    this.error = null;

                    this.track({
                        name: "NameNumberCollected",
                        category: "seller",
                        label: "Collected Name",
                        value: 1,
                    });

                    this.$root.$emit("next");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>